export class DeviceInfo{
  operatingSystem: string;
  browserName: string;
  device: string;
  oSVersion: string;
  browserVersion: string;
  latitude: string;
  longitude: string;
  iPaddress: string;

  constructor() {
    this.operatingSystem = null;
    this.browserName = null;
    this.device = null;
    this.oSVersion = null;
    this.browserVersion = null;
    this.latitude = null;
    this.longitude = null;
    this.iPaddress = null;

  }
}
export class OptInEBills {
    public id: string;
    public consumerId: string;
    public accountNumber: string;
    public isOptInEmail: boolean;
    public utilityId: string;
    public consumerEmail: string;
    public deviceInfo: DeviceInfo;
    public signatureData:string;
    constructor() {
        this.id = null;
        this.consumerId = null;
        this.accountNumber = null;
        this.isOptInEmail = false;
        this.utilityId = null;
        this.consumerEmail = null;
        this.deviceInfo = new DeviceInfo();
        this.signatureData = null;
    }
}
