export class ConsumerFilter {
    public accountNumber: string;
    public name: string;
    public utilityId: string;
    // public from: string;
    // public to: string;
    constructor()
    {
        this.accountNumber = null;
        this.name = null;
        this.utilityId = null;
        // this.from = null;
        // this.to = null;
    }
}
